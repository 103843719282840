import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Navigate, useRoutes } from 'react-router-dom';
import usePermissions from './hooks/usePermissions';
import LazyLoader from './utils/LazyLoader';
import { isAuthenticated } from './utils/axiosApi';

const TemplateLayout = React.lazy(() =>
  import('./components/TemplateLayout'),
);
const LoginPageLayout = React.lazy(() =>
  import('./components/TemplateLayoutLogin'),
);
const DashboardDrooky = React.lazy(() =>
  import('./components/drooky/dashboard'),
);
const SuiviList = React.lazy(() =>
  import('./components/drooky/suiviList'),
);
const QuoteList = React.lazy(() =>
  import('./components/drooky/quoteList'),
);
const ContractList = React.lazy(() =>
  import('./components/drooky/contractList'),
);
const ContractDetailsDrooky = React.lazy(() =>
  import('./components/drooky/contractDetails'),
);
const LoginPage = React.lazy(() => import('./components/login'));
const Home = React.lazy(() => import('./components/home'));
const Unauthorized = React.lazy(() =>
  import('./components/unauthorized'),
);
const Logout = React.lazy(() => import('./components/logout'));
const Affiliates = React.lazy(() =>
  import('./components/affiliates'),
);
const DashboardEtiq = React.lazy(() =>
  import('./components/etiq/dashboard'),
);
const Contracts = React.lazy(() =>
  import('./components/etiq/contracts'),
);
const ClaimsEtiq = React.lazy(() =>
  import('./components/common/claims'),
);
const ClaimsDrooky = React.lazy(() =>
  import('./components/drooky/claimList'),
);
const Users = React.lazy(() =>
  import('./components/etiq/users/usersList'),
);
const ManageUser = React.lazy(() =>
  import('./components/etiq/users/user'),
);

const Bills = React.lazy(() => import('./components/etiq/bills'));

const Admin = React.lazy(() => import('./components/etiq/admin'));
const CreateCollege = React.lazy(() =>
  import('./components/etiq/admin/college'),
);
const CreateMutualisation = React.lazy(() =>
  import('./components/etiq/admin/mutualisation'),
);
const CreateInsurer = React.lazy(() =>
  import('./components/etiq/admin/insurer'),
);
const CreateManager = React.lazy(() =>
  import('./components/etiq/admin/manager'),
);
const CreateFranchise = React.lazy(() =>
  import('./components/etiq/admin/companygroup'),
);
const CreateKnownCompany = React.lazy(() =>
  import('./components/etiq/admin/knowncompany'),
);
const ListTeleFail = React.lazy(() =>
  import('./components/etiq/admin/listTeleFail'),
);
const ClaimDetailsDrooky = React.lazy(() =>
  import('./components/drooky/claimDetails'),
);
const ClaimDetailsEtiq = React.lazy(() =>
  import('./components/etiq/claim'),
);
const AffiliateCreate = React.lazy(() =>
  import('./components/affiliates/create'),
);
const AffiliateDetails = React.lazy(() =>
  import('./components/affiliates/details'),
);

const generateDrookyRoutes = (canAccess, isDrookyAdmin) => {
  let element = null;
  if (!isAuthenticated()) {
    element = <Navigate to="/login" />;
  } else if (isAuthenticated() && !canAccess) {
    element = <Navigate to="/unauthorized" />;
  } else {
    element = <TemplateLayout app="drooky" />;
  }

  const routes = !isDrookyAdmin
    ? [
        { path: '', element: <DashboardDrooky /> },
        { path: 'suivis', element: <SuiviList /> },
        { path: 'quotes', element: <QuoteList /> },
        { path: 'contracts', element: <ContractList /> },
        {
          path: 'contracts/:contractId',
          element: <ContractDetailsDrooky />,
        },
        { path: 'claims', element: <ClaimsDrooky /> },
        {
          path: 'claims/:claimId',
          element: <ClaimDetailsDrooky />,
        },
      ]
    : [
        { path: '', element: <DashboardDrooky /> },
        { path: 'suivis', element: <SuiviList /> },
        { path: 'quotes', element: <QuoteList /> },
        { path: 'contracts', element: <ContractList /> },
        {
          path: 'contracts/:contractId',
          element: <ContractDetailsDrooky />,
        },
        { path: 'claims', element: <ClaimsDrooky /> },
        { path: 'claims/:claimId', element: <ClaimDetailsDrooky /> },
        { path: 'affiliates', element: <Affiliates /> },
        { path: 'affiliates/create', element: <AffiliateCreate /> },
        {
          path: 'affiliates/:affiliateId',
          element: <AffiliateDetails />,
        },
      ];

  return {
    path: '/drooky',
    element,
    children: routes,
  };
};

const generateEtiqRoutes = (canAccess) => {
  let element = null;
  if (!isAuthenticated()) {
    element = <Navigate to="/login" />;
  } else if (isAuthenticated() && !canAccess) {
    element = <Navigate to="/unauthorized" />;
  } else {
    element = <TemplateLayout app="etiq" />;
  }
  return {
    path: '/etiq',
    element,
    children: [
      { path: '', element: <DashboardEtiq /> },
      { path: 'contracts', element: <Contracts /> },
      { path: 'claims', element: <ClaimsEtiq app="etiq" /> },
      { path: 'claims/:claimId', element: <ClaimDetailsEtiq /> },
      { path: 'users', element: <Users /> },
      { path: 'users/:userId', element: <ManageUser /> },
      { path: 'bills', element: <Bills /> },
      { path: 'admin', element: <Admin /> },
      {
        path: 'admin/mutualisation',
        element: <CreateMutualisation />,
      },
      { path: 'admin/college', element: <CreateCollege /> },
      { path: 'admin/manager', element: <CreateManager /> },
      { path: 'admin/insurer', element: <CreateInsurer /> },
      { path: 'admin/knowncompany', element: <CreateKnownCompany /> },
      { path: 'admin/companygroup', element: <CreateFranchise /> },
      {
        path: 'admin/teletransmissionFail',
        element: <ListTeleFail />,
      },
    ],
  };
};
export default function Router() {
  const { permissions } = usePermissions();
  const { canAccessToDrooky, canAccessToEtiq, isDrookyAdmin } =
    permissions;

  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <Suspense fallback={<LazyLoader />}>
        {useRoutes([
          {
            path: '/',
            element: isAuthenticated() ? (
              <TemplateLayout />
            ) : (
              <Navigate to="/login" />
            ),
            children: [{ path: '/', element: <Home /> }],
          },
          {
            path: '/logout',
            element: isAuthenticated() ? (
              <Logout />
            ) : (
              <Navigate to="/login" />
            ),
          },
          {
            path: '/unauthorized',
            element: <TemplateLayout />,
            children: [
              { path: '/unauthorized', element: <Unauthorized /> },
            ],
          },
          {
            path: '/login',
            element: !isAuthenticated() ? (
              <LoginPageLayout />
            ) : (
              <Navigate to="/" />
            ),
            children: [{ path: '/login', element: <LoginPage /> }],
          },
          { ...generateEtiqRoutes(canAccessToEtiq) },
          {
            ...generateDrookyRoutes(canAccessToDrooky, isDrookyAdmin),
          },
          {
            path: '*',
            element: <Navigate to="/login" replace />,
          },
        ])}
      </Suspense>
    </CookiesProvider>
  );
}
